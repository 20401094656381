const Layout = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="bg-background h-screen w-screen flex flex-col items-center">
      <header className="text-outline w-full flex justify-center text-lg font-bold pt-3">
        Waldo
      </header>
      <main className="flex justify-center w-[300px]">{children}</main>
    </div>
  )
}

export default Layout
