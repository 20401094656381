import App from "./App"
import { Puzzle } from "./pages/challenge/PuzzlePage"
import { Home } from "./pages/home/Home"
export const routes = [
  {
    element: <App />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "find/:puzzleId",
        element: <Puzzle />,
      },
    ],
  },
]
