import { useParams } from "react-router-dom"
import { Button } from "../../shared/components/Button"

export const Puzzle = () => {
  const { puzzleId } = useParams<{ puzzleId: string }>()

  return (
    <div className="flex flex-col items-center pt-8 h-full gap-y-3">
      <img
        className="w-[300px] h-[450px] rounded-2xl border-outline border object-cover"
        src="https://store-app-images.s3.us-east-1.amazonaws.com/ab3e924c39a28c4721e8b70ae3afe5ab-393x852.png"
        alt="yah"
        width={100}
        height={200}
      />
      <div className="flex flex-row w-full gap-x-3">
        <Button className="w-3/4">Found it!</Button>
        <Button className="w-1/4">Li</Button>
      </div>
    </div>
  )
}
