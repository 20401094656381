import { Link } from "react-router-dom"

export const Home = () => {
  return (
    <div>
      Home
      <Link to="/find/test">Puzzle 1</Link>
    </div>
  )
}
